/** @jsx jsx */
import { jsx } from 'theme-ui';
import { H3, H4, P } from '../components/Text';
import { AccessibilityLayout } from '../layouts/AccessibilityLayout';

const AccessibilityPage = () => {
  return (
    <AccessibilityLayout>
      <P>
        The Interaction Design Association is committed to creating an event that is inclusive and welcoming to all attendees.We believe that inclusive design means removing barriers to access.On the Web, that means removing access to consuming content. 
      </P>
      <P>This year, we went big. Like, global. We are making Interaction21 available to new audiences in the following different ways. </P>
      <ul sx={{ml: 5}}>
        <li><P><span sx={{fontWeight: "bold"}}>1:1 ticket-matching program:</span> Conferences are expensive. This year, we created a 1:1 ticket-matching program to provide access to attendees who would otherwise be unable to shoulder the cost of the ticket and travel expenses. For every full price ticket sold, we've given one to someone who couldn't afford it.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Timezone-agnostic broadcast:</span> We’ve gone 24 hours. Our 8-hour daily program will be rebroadcast to accommodate 3 global time zones so that people from all over the world can attend and socialize at a time most convenient to them.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Live captioning for the deaf or hard of hearing:</span> We've partnered with ACS to provide best-in-class captioning for all our sessions. </P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Live translation:</span> To accomodate speakers who don’t feel comfortable giving their talk in English, their sessions will be captioned in English.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Website colours:</span> This year's theme "Design In Perilous Times" and visual identity were intentionally meant to be uncomfortable. However, high-contrast colours are sometimes uncomfortable for the eyes when displayed on screens. That is why we provided an easily accessible dark-mode switcher for our website pages on all devices.</P></li>
      </ul>
      <H4>For More Information</H4>
      <P>
        If you have any request to submit to us or if you would like to make us
        aware of any accessibility concern, please contact us at&nbsp;
        <a
          href="mailto:interaction21@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          interaction21@ixda.org
        </a>
        .
      </P>
    </AccessibilityLayout>
  );
};

export default AccessibilityPage;
