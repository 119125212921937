/** @jsx jsx */
import { jsx } from 'theme-ui';

import MainLayout from './MainLayout';
import { Container } from '../components/Container';
import { H2 } from '../components/Text';
import { Box } from 'rebass';
import { useTranslation } from '../i18n/TranslationContext';

export const AccessibilityLayout: React.FC = ({ children }) => {
  const t = useTranslation();
  return (
    <MainLayout>
      <Container>
        <H2 sx={{ mb: 3 }} id="content">
          {t('accessibility_title')}
        </H2>
        <Box
          sx={{
            pb: [3, 4],
            mb: [3, 4],
            borderBottom: 'solid 1px',
            borderColor: 'text',
          }}
        />
      </Container>
      <Container>{children}</Container>
    </MainLayout>
  );
};
